import { Templet1 } from "../templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Bredbandsforums styrgrupp"
        topParagraph="Bredbandsforum arbete leds av en styrgrupp. Statsrådet som ansvarar för digitaliseringsfrågor i regeringen är styrgruppens ordförande och utser dess ledamöter. Styrgruppen består av nyckelaktörer som är viktiga för strategins genomförande."
        images="/media/1114/styrgruppen.png"
        textbody={[
          <h3>Styrgruppen visar vägen</h3>,
          <p>
            Styrgruppen bestämmer inriktning för forumets arbete och beslutar om
            direktiv för{" "}
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbetsgrupper"
            >
              arbetsgrupper
            </a>
            . Styrgruppen är mottagare av förslag från arbetsgrupper och följer
            upp genomförande av förslagen. Du kan ta del av{" "}
            <a
              data-udi="umb://document/c4deb14b8bd74601a6da2cbd5fec3dd1"
              href="/om-bredbandsforum/material-fraan-styrgruppen/"
              title="Material från styrgruppen"
            >
              mötesanteckningar och övrigt material
            </a>{" "}
            från Bredbandsforums styrgruppsmöten. Ledamöterna förordnas
            personligen och företräder sin organisation i styrgruppen.
          </p>,
          <h3>Styrgruppens avsiktsförklaring</h3>,
          <p>
            Bredbandsforums styrgrupp har enats om en{" "}
            <a
              data-udi="umb://media/2de72c2fd9554394939c3182dd72bfc3"
              href="/media/1176/bredbandsforums-styrgrupp-avsiktsfoerklaring-2017.pdf"
              title="Bredbandsforums styrgrupp - Avsiktsförklaring 2017.pdf"
            >
              gemensam avsiktsförklaring
            </a>{" "}
            som sammanfattar vad Bredbandsforum är och vad deltagarna vill uppnå
            med forumet:
          </p>,
          <table className="greenbox">
            <tbody>
              <tr>
                <td>
                  <p>
                    <em>
                      Vi delar regeringens mål om att Sverige ska vara bäst i
                      världen på att använda digitaliseringens möjligheter. En
                      förutsättning för det är att det finns tillgång till
                      infrastruktur för elektronisk kommunikation i hela landet
                      som kan bära nuvarande och kommande privata och offentliga
                      tjänster. Sverige ska därför ha bredband i världsklass och
                      vara helt uppkopplat år 2025.
                    </em>
                  </p>
                  <p>
                    <em>
                      Utifrån regeringens Bredbandsstrategi för Sverige arbetar
                      vi för att bidra till att målen nås. Det innebär att alla
                      hushåll och företag bör ha goda möjligheter att använda
                      sig av elektroniska samhällstjänster och service via
                      bredband. År 2020 bör minst 95 procent av alla hushåll och
                      företag ha tillgång till bredband om minst 100 Mbit/s. År
                      2023 bör hela Sverige ha tillgång till mobila tjänster av
                      god kvalitet och år 2025 bör hela Sverige ha tillgång till
                      snabbt bredband.
                    </em>
                  </p>
                  <p>
                    <em>
                      Behoven och efterfrågan behöver mötas av utbyggnad av en
                      framtidssäker infrastruktur i hela landet. Strategin och
                      forumets arbete syftar till att motivera alla marknadens
                      aktörer, privata och offentliga, att bidra till en
                      fortsatt snabb utbyggnad. En väl fungerande konkurrens är
                      här ett viktigt medel.
                    </em>
                  </p>
                  <p>
                    <em>
                      För att målen ska nås behöver vi ha en konstruktiv
                      diskussion och samverkan i vissa frågor mellan olika
                      aktörer som har intresse av och påverkan på utbyggnaden.
                      Bredbandsforum är här en viktig del av regeringens
                      bredbandsstrategi. Forumet främjar samverkan kring
                      bredbandsutbyggnad, och är en plattform för diskussion och
                      kunskapsutbyte. I forumet får vi mer kunskap om varandras
                      verksamheter, dagliga situation och framtida utmaningar.
                    </em>
                  </p>
                  <p>
                    <em>Vi arbetar aktivt tillsammans för att</em>
                  </p>
                  <ul>
                    <li>
                      <em>
                        bidra till stärkt dialog och samverkan mellan berörda
                        aktörer,
                      </em>
                    </li>
                    <li>
                      <em>
                        stödja och bidra till ett ökat engagemang på kommunal
                        och regional nivå,
                      </em>
                    </li>
                    <li>
                      <em>
                        gemensamt arbeta fram konstruktiva förslag till åtgärder
                        som förbättrar förutsättningar för utbyggnad och
                        tillgång till bredband i hela landet och bidra till att
                        förslagen genomförs,
                      </em>
                    </li>
                    <li>
                      <em>
                        bidra till ökad kunskap och medvetenhet om betydelsen av
                        bredband på lokal, regional och nationell nivå samt om
                        regeringens bredbandsstrategi.
                      </em>
                    </li>
                  </ul>
                  <p>
                    <em>
                      Som ledamot i Bredbandsforum styrgrupp verkar jag för att
                      öka kännedomen om strategin och dess mål, för en
                      konstruktiv samverkan inom forumet för att undanröja
                      hinder för utbyggnad och för att förankra forumets arbete
                      inom min organisation.
                    </em>
                  </p>
                  <p>
                    <strong>
                      Bredbandsforums styrgrupp <span>–</span> Stockholm den 29
                      mars 2017
                    </strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>,
          <p>&nbsp;</p>,
        ]}
        cardtitle="Ledamöter i Bredbandsforums styrgrupp"
        carddata={[
          {
            textbody: (
              <div>
                <p>
                  <strong>Erik Slottner</strong>
                  <br />
                  Civilminister
                </p>
                <p>
                  <strong>Dan Sjöblom</strong>
                  <br />
                  Post- och telestyrelsen
                </p>
                <p>
                  <strong>Anders Olsson</strong>
                  <br />
                  Telia Sverige
                </p>
                <p>
                  <strong>Bjørn Ivar Moen</strong>
                  <br />
                  Telenor
                </p>
                <p>
                  <strong>Haval van Drumpt</strong>
                  <br />
                  Tre
                </p>
                <p>
                  <strong>Carl-Johan Rydén</strong>
                  <br />
                  Tele 2
                </p>
                <p>
                  <strong>Charlotta Lundell Berg</strong>
                  <br />
                  GlobalConnect
                </p>
                <p>
                  <strong>Patrik Forslund</strong>
                  <br />
                  Ericsson
                </p>
                <p>
                  <strong>Mikael Ek</strong>
                  <br />
                  Svenska Stadsnätsföreningen
                </p>
                <p>
                  <strong>Åsa Zetterberg</strong>
                  <br />
                  TechSverige
                </p>
                <p>
                  <strong>Henrik Selin</strong>
                  <br />
                  SVT
                </p>
                <p>
                  <strong>Märta Molin</strong>
                  <br />
                  Region Västernorrland
                </p>
                <p>
                  <strong>Anna Hamberg</strong>
                  <br />
                  SKR
                </p>
                <p>
                  <strong>Terese Bengard</strong>
                  <br />
                  Hela Sverige Ska leva
                </p>
                <p>
                  <strong>Katarina Ljunggren</strong>
                  <br />
                  Länsstyrelsen i Norrbottens län
                </p>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;
